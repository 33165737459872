import Spreadsheet from "../assets/Spreadsheet.jpg";
import pixelDrawer from "../assets/pixelDrawer.jpeg";
import GameDay from "../assets/GameDay.jpg";
import Todolist from "../assets/todolist.webp";
import simonGame from "../assets/simonGameImage.jpeg";
import DOF from "../assets/depthOfPerceptionimg.png";
import FFR from "../assets/ffrimg.png";
import uDraw from "../assets/uDraw.jpg";
import MusicmateImg from "../assets/MusicMate.png";
import SSEHImage from "../assets/SSEHImage.PNG";
import firestrike_img from "../assets/vr_img.jpeg";
import icegunga_img from "../assets/icegunga_img.png";
import socialmedia_img from "../assets/socialmedia.jpeg";
import PantryHelper_img from "../assets/PantryHelperLogo.png";
import Ball_img from "../assets/BallLogo.png";

// import pixelDrawerDoc from "../documents/pixelDrawerDescription.pdf";
// import SpreadsheetREADME from "../documents/SpreadsheetREADME.pdf";
import GameDayWhitePaper from "../documents/GameDayWhitePaper.pdf";
import GameDayInfographic from "../documents/GameDayInfographic.pdf";
import simonGameREADME from "../documents/SimoneGameREADME.pdf";
import pantryHelperREADME from "../documents/PantryHelperDesignDoc.pdf";
import androidDrawingREADME from "../documents/AndroidDrawingApp.pdf";
import marbleGameREADME from "../documents/MarbleGameREADME.pdf";
import SSEHDemo from "../documents/SSEH.pdf";
// import DOFREADME from "../documents/unityDoFREADME.pdf";
// import FFRREADME from "../documents/UnityFFRREADME.pdf";
// import firestrikePPT from "../documents/firestrike_ppt.pdf";
// import icegungeREADME from "../documents/IceGungaMasterREADME.pdf";

export const ProjectList = [
  {
    name: "Capstone Project (Coming soon)",
    image: PantryHelper_img,
    skills: "React.js, Javascript, Node.js, Mysql, AWS EC2 - RDS",
    githubLink: pantryHelperREADME,
    demoLink: "https://youtu.be/lU-x-t5yaQU",
  },
  {
    name: "MusicMate by Dasoni",
    image: MusicmateImg,
    skills: "Java, Kotlin, Android Studio",
    githubLink:
      "https://www.figma.com/design/l879seOVPf0LIijH7miIE3/%EB%AE%A4%EC%A7%81%EB%A9%94%EC%9D%B4%ED%8A%B8?node-id=0-1&t=yE7fiWxyT3i4d640-0",
    demoLink: "https://youtu.be/sn2otse0ado",
  },
  {
    name: "Automated Education Program",
    image: SSEHImage,
    skills: ".NET(C#), SQL, Gemini API",
    githubLink:
      "https://www.figma.com/design/eyv73ksw87mLXSwI2laqyC/%EA%B5%AC%EA%B8%80-Gemini-API-%ED%95%B4%EC%BB%A4%ED%86%A4-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8?node-id=0-1&t=bIOtX0w6NWdTwTfU-0",
    demoLink: SSEHDemo,
  },
  {
    name: "Pixel Drawer",
    image: pixelDrawer,
    skills: "Qt Creator, C++",
    githubLink: "https://gitfront.io/r/seojungood/7d5LC6SfnN65/pixel-drawer/",
    demoLink: "https://youtu.be/o6MAB7HMz7c",
  },
  {
    name: "Unity - Depth of Perception",
    image: DOF,
    skills: "C#, Unity",
    githubLink:
      "https://gitfront.io/r/seojungood/7UwQWBQzPkKN/assignment-2-transformations-and-depth-perception-seojungood/",
    demoLink: "https://youtu.be/-NoYFAVE6hQ",
  },
  {
    name: "VR - FireStrike",
    image: firestrike_img,
    skills: "C#, Unity",
    githubLink: "https://github.com/seojungood/VR-Firestrike",
    demoLink: "https://youtu.be/dsrShgEOhGo",
  },
  {
    name: "Qt Creator - Icecream Making Tutorial",
    image: icegunga_img,
    skills: "Qt Creator, C++, Box2D",
    githubLink: "https://github.com/seojungood/Icecream-make-tutorial",
    demoLink: "https://youtu.be/ymb5l3xP8Z4",
  },
  {
    name: "Unity - Fixed Foveated Rendering / Bloom Effect",
    image: FFR,
    skills: "C#, Shader, Unity",
    githubLink:
      "https://gitfront.io/r/tjwnsgood/UAAmvwMyKoYN/assignment-3-foveated-rendering-seojungood/",
    demoLink: "https://youtu.be/C26XB8kTuMw",
  },
  {
    name: "Android - Drawing App",
    image: uDraw,
    skills: "Android Studio, Kotlin",
    githubLink: androidDrawingREADME,
    demoLink: "https://youtu.be/rX4tNazfEQc",
  },
  {
    name: "Spreadsheet",
    image: Spreadsheet,
    skills: "C#, MAUI, XAML",
    githubLink: "https://gitfront.io/r/seojungood/aNVx1mgykFWj/spreadsheet/",
    demoLink: "https://youtu.be/qKMoC4Z3LO0",
  },
  {
    name: "Android - Marble Game",
    image: Ball_img,
    skills: "Android Studio, Kotlin",
    githubLink: marbleGameREADME,
    demoLink: "https://youtu.be/qaz4TH9BXXE",
  },
  {
    name: "Super Bowl Twitter Data Analysis",
    image: GameDay,
    skills: "Python, Tableau, Tableau Prep",
    githubLink: GameDayWhitePaper,
    demoLink: GameDayInfographic,
  },
  {
    name: "Social Media App (Learning Cloud)",
    image: socialmedia_img,
    skills: "Typescript, React, HTML/CSS, Firebase(Cloud)",
    githubLink: "https://github.com/seojungood/Social-Media-Project",
    demoLink: "https://youtube.com/shorts/cqZWYH93yJg",
  },
  {
    name: "Simon Game",
    image: simonGame,
    skills: "Qt Creator, C++",
    githubLink: simonGameREADME,
    demoLink: "https://youtu.be/xqcWQGgMkao",
  },
  {
    name: "Todo List (Next.js)",
    image: Todolist,
    skills: "Next.js, TypeScript, Prisma, Tailwind css",
    githubLink: "https://github.com/seojungood/TodoList-nextjs",
    demoLink: "https://youtu.be/Ulvz1D29GDA",
  },
];

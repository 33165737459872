import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SchoolIcon from "@material-ui/icons/School";
import WorkIcon from "@material-ui/icons/Work";
import CertificateIcon from "@material-ui/icons/AssignmentTurnedIn";
import { Link } from "react-router-dom";

import "../styles/About.css";

function About() {
  return (
    <div className="experience">
      <div className="aboutme">
        <h2>Hello again, My name is Seojun Chung</h2>
        {/* <p> 
            I'm a software developer and currently a student at the 
            University of Utah majoring in Computer Science.
          </p>
          <p>
            I have great passion and ambition towards building applications 
            and  
          </p> */}
      </div>
      <VerticalTimeline lineColor="black">
        <VerticalTimelineElement
          date="2016 - 2020"
          iconStyle={{ background: "black", color: "white" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Orem High School, Orem, UT
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            High School Diploma
          </h4>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="2021 - 2023"
          iconStyle={{ background: "white", color: "black" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            QC Inspector - LBSOL
          </h3>
          <h4 className="vertical-timeline-element-subtitle">Orem, UT</h4>
          <p>
            <a href="https://www.dpque.com/">DPQUE.com</a> - Cloud based Digital
            Signage Platform
          </p>
          <p>
            <a href="https://www.crowncertificate.com/">
              Crown Certificate.com
            </a>{" "}
            - Protection Plan for Crown Treatment
          </p>
          <p>
            - Collaborate with product development and engineering team to
            understand the features and architecture of web-based applications
          </p>
          <p>
            - Identify and analyze defects in web-based applications to carry
            out testing
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="January 8th 2023"
          iconStyle={{ background: "black", color: "white" }}
          icon={<CertificateIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Goldman Sachs Fintech Engineering Virtual Experience Program
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Certificate of Completion
          </h4>
          <p>
            Acquired skills in basic cryptography, password cracking, and
            developed critical engineering skills (HashCat)
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="February 24th, 2023"
          iconStyle={{ background: "black", color: "white" }}
          icon={<CertificateIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Information Systems - Super Bowl Game Day Analytics Challenge
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Certificate of Participation
          </h4>
          <p>
            - Analyzed Super Bowl commercial tweets from Twitter API using
            Python to provide valuable insights to advertisers, including
            sentiment and return on ad spend.{" "}
          </p>
          <p>
            - Designed data visualizations and dashboards using Tableau and
            Tableau Prep to present key findings to panel of judges.
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="August 2020 - December 2024"
          iconStyle={{ background: "black", color: "white" }}
          icon={<SchoolIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            University Of Utah, SLC, UT
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Bachelor's Degree
          </h4>
          <p>B.S. in Computer Science</p>
          <p>- Dean's List for all semesters</p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="August 2024"
          iconStyle={{ background: "black", color: "white" }}
          icon={<CertificateIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Google Gemini API Competition
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Automated Education Program - .Net (C#), SQL
          </h4>
          <p>
            - Developed a program that accepts PDFs or text files, analyzes key
            vocabularies, provides definitions, and generates practice exams to
            enhance user learning.
          </p>
          <p>
            - Integrated Gemini's AI-powered features into a notebook concept,
            enabling users to share notes socially and facilitating independent
            study
          </p>
          <p>
            <a href="https://www.figma.com/design/eyv73ksw87mLXSwI2laqyC/%EA%B5%AC%EA%B8%80-Gemini-API-%ED%95%B4%EC%BB%A4%ED%86%A4-%ED%94%84%EB%A1%9C%EC%A0%9D%ED%8A%B8?node-id=0-1&t=bIOtX0w6NWdTwTfU-0">
              Figma Components
            </a>
          </p>
          <p>
            <Link to="/project/2">More...</Link>
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="June 2024 - September 2024"
          iconStyle={{ background: "black", color: "white" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Android Developer - Dasoni
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Remote, Rep. of Korea
          </h4>
          <p>
            - Developed and enhanced the user interface and experience for
            Musicmate, a government-supported Android app that empowers
            individuals with intellectual disabilities to discover and train
            their musical abilities through engaging, game-like interactions
          </p>
          <p>
            - Implemented accessible features using Firebase, enhancing user
            experience and learning outcomes for disabled users.
          </p>
          <p>
            - Implemented accessible features using Firebase, enhancing user
            experience and learning outcomes for disabled users.
          </p>
          <p>
            <a href="https://www.figma.com/design/l879seOVPf0LIijH7miIE3/%EB%AE%A4%EC%A7%81%EB%A9%94%EC%9D%B4%ED%8A%B8?node-id=0-1&t=yE7fiWxyT3i4d640-0">
              Figma Components
            </a>
          </p>
          <p>
            <Link to="/project/1">More...</Link>
          </p>
        </VerticalTimelineElement>

        <VerticalTimelineElement
          className="vertical-timeline-element--education"
          date="Feb 2024 - Present"
          iconStyle={{ background: "black", color: "white" }}
          icon={<WorkIcon />}
        >
          <h3 className="vertical-timeline-element-title">
            Full-Stack Intern - Innosys, Inc
          </h3>
          <h4 className="vertical-timeline-element-subtitle">
            Salt Lake City, UT
          </h4>
          <p>
            <a href="https://www.ilumens.com/">iLumens, LLC</a> - IoT sensor and
            controls company
          </p>
          <p>
            - Developed and managed cloud-based IoT applications, utilizing
            React frameworks and TypeScript with Python and Django
          </p>
          <p>
            - Developed and maintained code for communication with the Astarte
            database and implemented RESTful APIs, enabling seamless data
            exchange and packet transmission to ESP devices for real-time
            monitoring and control.
          </p>
          <p>
            - Acquired IoT-specific knowledge by managing real-time data streams
            and creating data visualization tools, leading to improved
            operational efficiency.
          </p>
          <p>
            - Collaborated in an Agile work environment, contributing to
            iterative development, continuous integration, and regular sprint
            reviews to enhance project efficiency and team communication.
          </p>
        </VerticalTimelineElement>
      </VerticalTimeline>
    </div>
  );
}

export default About;
